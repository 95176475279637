import * as React from 'react';
import Layout from '../components/layout';
import Home from '../components/home';

const IndexPage = () => {
  return (
    <Layout>
      <Home></Home>
    </Layout>
  );
};

export default IndexPage;
